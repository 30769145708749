








































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BasePageHeader from '@improve/common-components/src/components/widgets/BasePageHeader.vue';
import StatsBlock from '@improve/common-components/src/components/widgets/StatsBlock.vue';
import Topic from '@improve/common-utils/src/model/Topic';
import lightbulbIcon from '@improve/common-components/src/assets/lightbulb.svg';
import genericUser from '@improve/common-components/src/assets/generic_user.svg';
import StatsData from '@improve/common-utils/src/model/StatsData';
import BaseTabOption from '@improve/common-utils/src/types/TabOption';
import User from '@improve/common-utils/src/model/User';

@Component({
  name: 'TopicDetails',
  components: {
    BasePageHeader,
    StatsBlock
  }
})
export default class TopicDetails extends Vue {
  @Getter topicStatsById!: Map<string, StatsData>;

  @Getter organizationTopicsById!: (id: string) => Topic;

  @Action fetchTopicStatsByBatch!: ActionMethod;

  @Action fetchTopicSubscriptions!: ActionMethod;

  @Action fetchUsersByBatch!: ActionMethod;

  experts: Array<User> = [];

  lightBulbIcon = lightbulbIcon;

  genericUser = genericUser;

  options: Array<BaseTabOption> = [];

  get topic(): Topic | undefined {
    return this.organizationTopicsById && this.organizationTopicsById(this.$route.params.id);
  }

  get topicName(): string {
    return this.topic?.metaData.displayName || ' ';
  }

  get currentTab(): string {
    return this.$route.name || '';
  }

  async created(): Promise<void> {
    await this.fetchTopicStatsByBatch([this.topic]);
    await this.fetchSubscriptions();
    this.initTabs();
  }

  async fetchSubscriptions(): Promise<void> {
    if (!this.topic || !this.topic.id) {
      return;
    }
    const subs: Map<string, number> = await this.fetchTopicSubscriptions(this.topic);
    this.experts = await this.fetchUsersByBatch(User.getUserIdsByRole(subs));
  }

  getTopicStats(topicId: string): StatsData {
    return this.topicStatsById && this.topicStatsById.has(topicId)
      ? this.topicStatsById.get(topicId)!
      : new StatsData();
  }

  initTabs(): void {
    this.options = [
      {
        title: this.$t('page.userProfile.topicsTab.topicsImprovesTabTitle').toString(),
        value: 'TopicImproves'
      },
      {
        title: this.$t('page.userProfile.topicsTab.topicsExpertsTabTitle').toString(),
        value: 'TopicExperts',
        disabled: !this.experts.length
      }
    ];
  }

  goToTab(tabValue: string): void {
    if (tabValue !== this.currentTab) {
      this.$router.push({ name: tabValue });
    }
  }
}
